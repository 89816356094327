import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['number', 'label', 'wrapper', 'left', 'right']
  static classes = ['hidden']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    console.log(this.hiddenClass)
  }

  toggle() {
    this.rightTargets.forEach(right => {
      right.classList.toggle(this.class)
    })
    this.leftTargets.forEach(left => {
      left.classList.toggle(this.class)
    })
  }


  show() {
    this.rightTargets.forEach(right => {
      right.classList.add(this.class)
    })
    this.leftTargets.forEach(left => {
      left.classList.remove(this.class)
    })
  }

  user_show() {
    this.rightTargets.forEach(right => {
      right.classList.remove(this.class)
    })
    this.leftTargets.forEach(left => {
      left.classList.add(this.class)
    })
  }

  show_number() {
    this.labelTargets.forEach(label => {
      label.classList.add(this.class)
      console.log(label.classList)
    })
    this.numberTargets.forEach(number => {
      number.classList.remove(this.class)
    })
  }

  switch() {
    this.wrapperTargets.forEach(wrapper => {
      wrapper.classList.remove(this.class)
    })

  }

  hide() {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
  }
  
}
 