// countdown_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"];
  static values = { start: String };

  connect() {
    // Parse the start date
    this.startDate = new Date(this.startValue);

    // Start the countdown
    this.countdown();
  }

  countdown() {
    // Calculate the time remaining
    const now = new Date();
    const timeRemaining = Math.max(this.startDate.getTime() - now.getTime(), 0);

    // Calculate the number of days, hours, minutes, and seconds remaining
    const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hoursRemaining = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const minutesRemaining = Math.floor((timeRemaining / (1000 * 60)) % 60);
    const secondsRemaining = Math.floor((timeRemaining / 1000) % 60);

    // Display the time remaining
    this.daysTarget.textContent = daysRemaining;
    this.hoursTarget.textContent = hoursRemaining;
    this.minutesTarget.textContent = minutesRemaining;
    this.secondsTarget.textContent = secondsRemaining;

    if (timeRemaining > 0) {
      // Schedule the next update
      setTimeout(() => {
        this.countdown();
      }, 1000);
    }
  }
}
