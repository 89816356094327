import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["togglePin"]

  connect() {
    this.checkScroll()
    window.addEventListener('scroll', this.checkScroll.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.checkScroll.bind(this))
  }

  checkScroll() {
    const scrollPosition = window.scrollY
    const windowHeight = window.innerHeight
    const documentHeight = document.body.scrollHeight

    if (scrollPosition > documentHeight / 2 - windowHeight / 2) {
      this.togglePinTarget.classList.remove("hidden")
    } else {
      this.togglePinTarget.classList.add("hidden")
    }
  }
}
