/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Rails.start();
// Turbolinks.start()
ActiveStorage.start();

import 'controllers/front_controllers';
import '../components/front/';
/*import "@phosphor-icons/webcomponents";*/
import "@phosphor-icons/web";
import { Carousel, Collapse, Dropdown, Ripple, Sidenav, initTE } from "tw-elements";
initTE({ Carousel, Collapse, Dropdown, Ripple, Sidenav }, { allowReinits: true });

require("../css/application.scss");
require("@rails/actiontext");

