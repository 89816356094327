// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggled" ]
  static classes = [ "toggle" ]

  toggle(event) {
    event.preventDefault()
    // Unblurring focused target if there is one
    if (event.target) {
      document.activeElement.blur()
    }

    this.toggledTargets.forEach(
      (toggled) => toggled.classList.toggle(this.toggleClass)
    )
  }
}
