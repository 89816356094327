document.addEventListener("turbo:load", function (event) {
  let classname = document.getElementsByClassName("atc");
  for (let i = 0; i < classname.length; i++) {
    //click gauche
    classname[i].addEventListener('click', myFunction, false);
    //click droit
    classname[i].addEventListener('contextmenu', myRightFunction, false);
  }
});
//fonction du click gauche
let myFunction = function (event) {
  let attribute = this.getAttribute("data-atc");
  if (event.ctrlKey) {
    let newWindow = window.open(decodeURIComponent(window.atob(attribute)), '_blank');
    newWindow.focus();
  } else {
    document.location.href = decodeURIComponent(window.atob(attribute));
  }
};
//fonction du click droit
let myRightFunction = function (event) {
  let attribute = this.getAttribute("data-atc");
  if (event.ctrlKey) {
    let newWindow = window.open(decodeURIComponent(window.atob(attribute)), '_blank');
    newWindow.focus();
  } else {
    window.open(decodeURIComponent(window.atob(attribute)), '_blank');
  }
}
