import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "toggleable",
        "burgerOpen",
        "burgerClose",
    ]

    toggle() {
        this.toggleableTarget.classList.toggle('hidden')
        this.burgerOpenTarget.classList.toggle('hidden')
        this.burgerCloseTarget.classList.toggle('hidden')
    }
}