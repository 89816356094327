import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["countdown", "link"];

  connect() {
    this.startCountdown();
  }

  startCountdown() {
    let remainingTime = 15;
    this.updateCountdown(remainingTime);

    const countdownInterval = setInterval(() => {
      remainingTime--;
      this.updateCountdown(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(countdownInterval);
        this.hideCountdown();
        this.enableLink();
      }
    }, 1000);
  }

  updateCountdown(remainingTime) {
    this.countdownTarget.innerText = remainingTime;
  }

  hideCountdown() {
    this.countdownTarget.style.display = "none";
  }

  enableLink() {
    this.linkTarget.classList.remove("isDisabled");
    this.linkTarget.classList.add("link");
  }

}