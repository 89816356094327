import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["figure", "img"];

  connect() {
    this.figureTarget.style.backgroundImage = `url(${this.imgTarget.src})`;
    this.figureTarget.style.cursor = "zoom-in";
    this.imgTarget.style.opacity = 0;
    this.imgTarget.style.transition = "opacity .5s";
    this.figureTarget.addEventListener("mouseenter", () => {
      this.imgTarget.style.opacity = 1;
      setTimeout(() => {
        this.figureTarget.style.cursor = "zoom-out";
      }, 500);
    });
    this.figureTarget.addEventListener("mouseleave", () => {
      this.imgTarget.style.opacity = 0;
      this.figureTarget.style.cursor = "zoom-in";
    });
    this.figureTarget.addEventListener("mousemove", this.zoom);
    this.figureTarget.addEventListener("touchmove", this.zoom);
  }

  disconnect() {
    this.figureTarget.removeEventListener("mousemove", this.zoom);
    this.figureTarget.removeEventListener("touchmove", this.zoom);
  }

  zoom = (e) => {
    const zoomer = this.figureTarget;
    let offsetX, offsetY;
    if (e.offsetX) {
      offsetX = e.offsetX;
      offsetY = e.offsetY;
    } else if (e.touches) {
      offsetX = e.touches[0].pageX;
      offsetY = e.touches[0].pageY;
    }
    const x = offsetX / zoomer.offsetWidth * 100;
    const y = offsetY / zoomer.offsetHeight * 100;
    zoomer.style.backgroundPosition = `${x}% ${y}%`;
  };
}
